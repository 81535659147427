@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

    .section2{
        /* position: relative; */
        height: 550px;
        width: 100vw;
        background-color: #be5858;   
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

     .sec2contents{
        height: 100%;
        width: 100%;
        color: rgb(0, 0, 0);
    font-family: 'Outfit', sans-serif;
        font-size: 18px;

        /* margin-ttom: 100px; */

    } 

    .sec2title{
        font-size: 26px;
        color: rgb(255, 255, 255);
        margin-left: 15px;
    }

    .sec2contents p{
        margin: 30px;
    }
    .colsec2{
        color: white;
    }

    .playerconatiner{
        width: 100%;
        /* height: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    




