@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');


    .sec6page{
        position: relative;
        height: 100%;
        width: 100vw;
        background-color: #a0fbfb;
        display: flex;
        justify-content: center;
    }

    .custom-shape-divider-top-16426248176 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }
    
    .custom-shape-divider-top-16426248176 svg {
        position: relative;
        display: block;
        width: calc(139% + 1.3px);
        height: 49px;
        transform: rotateY(180deg);
    }
    
    .custom-shape-divider-top-16426248176 .shape-fill {
        fill: #ffd586;
    }
    
    /** For mobile devices **/
    @media (max-width: 767px) {
        .custom-shape-divider-top-1642624817 svg {
            width: calc(139% + 1.3px);
            height: 201px;
        }
    }


    .sec3textcon6{
        /* margin-top: 400px; */
        height: 100%;
        width: 80%;
        font-family: 'Outfit', sans-serif;
    }

    .sec6title{
        font-size: 26px;
        color: rgb(0, 0, 0);
        
    }

    .sec3textcon p{
        font-size: 18px;
    }
    
    .sec3col{
        color: rgb(68, 204, 68);
    }


    .faqdiv6{
        margin-top: 40px;
        margin-bottom: 100px;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      }


.sec6page a:-webkit-any-link {
    color: blue;
}

