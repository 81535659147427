.section5page{
    position: relative;
        height:1900px;
        width: 100vw;
        background-color: rgb(255, 255, 255);
        display: flex;
        /* align-items: center; */
        justify-content: center;
}

.custom-shape-divider-top-16426248175 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-16426248175 svg {
    position: relative;
    display: block;
    width: calc(139% + 1.3px);
    height: 49px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-16426248175 .shape-fill {
    fill: #ffd586;
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-top-16426248175 svg {
        width: calc(139% + 1.3px);
        height: 201px;
    }
}

.sec5title{
    margin-right: 60px;
    color: brown;
    /* position: relative; */

}

.faqdiv{
    /* margin-top: 90px; */
    width: 80%;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
}

.sec3textcon5{
    margin-top: 160px;
    display: flex;
    /* align-items:center; */
        font-family: 'Outfit', sans-serif;
        width: 90%;
    justify-content: center;
    /* background-color: yellow; */
}

.faqdiv{
    font-family: 'Outfit', sans-serif;
    margin-top: 120px;
    
}
.divpwrap{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    flex-direction: column;
    margin-top: 70px;
}

.relinks{
    width: 100%;
    height: 600px;
    /* background-color: tomato; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
}

.relink1, .relink2, .relink3{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.img1,.img2,.img3,.img4{
    width: 40%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    transition: transform 0.1s ease-in;
}

.img1:hover,.img2:hover,.img3:hover,.img4:hover
{
    transform: scale(1.1);
}

.relinks img{
    height: auto;
    width: 100px;
}

.col5{
    color: brown;
}

.modelresume{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
}

.modelresume span{
    color: brown;
    font-size: 24px;
}

.resuimg{
    margin-top: 30px;
    height: auto;
    width: 80%;
    margin-bottom: 30px;
}


.custom-shape-divider-bottom-164260900815{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-164260900815 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 141px;
}

.custom-shape-divider-bottom-164260900815 .shape-fill {
    fill: #a0fbfb;
    /* fill: #a33939; */

}

.section5page a:-webkit-any-link
{
    color: brown;
}

.braca{
    font-size: 14px;
}

