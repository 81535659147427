

    .section4page{
        position: relative;
        height: 720px;
        width: 100vw;
        background-color: #ffd586;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sec4title{
        color: black;
        margin-left: 15px;

    }

    .col4{
        color: rgb(0, 38, 255);
    }

