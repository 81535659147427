
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');


:root {
    --primColor: rgb(255, 255, 255);
    --secoColor: #000000;
    --cornerRad: 4px;
  }
  body {
     /* background-color: rgb(29, 27, 27); */
     font-family: 'Outfit', sans-serif;
    font-size: 20px;
  }
  details {
    margin-left: 30px;
    margin-top: 8px;
  }
  summary {
    writing-mode: vertical-lr;
    text-align: center;
    padding: 10px 10px;
    width: 22px;
    height: 15px;
    background-color: var(--primColor);
    border: 2px solid var(--secoColor);
    border-radius: var(--cornerRad);
    color: var(--secoColor);
    cursor: pointer;
    user-select: none;
    outline: none;
    transition: transform 200ms ease-in-out 0s;

  }
  summary::before,
  summary::after {
    position: static;
    top: 0;
    left: 0;

  }
  summary::before {
    content: "";
  }
  summary::after {
    content: "III";
    letter-spacing: -1px;
    /* transform: scale(1.1); */

  }
  summary:hover {
    transform: scale(1.1);
    /* border: 2px solid var(--secoColor); */
    border-radius: var(--cornerRad);
    color: var(--secoColor);

  }
  summary::marker {
    font-size: 0;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  details[open] .menu {
    animation-name: menuAnim;
  }
  details[open] summary::before {
    content: "x";
  }
  details[open] summary::after {
    content: "";
  }
  .menu {
    height: 0;
    width: fit-content;
    border-radius: var(--cornerRad);
    background-color: black;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    animation: closeMenu 300ms ease-in-out forwards;
  }
  .linksfornav{
    padding: 12px 24px;
    margin: 0 16px;
    color: rgba(255, 255, 255, 0.822);
    /* border-bottom: 1px solid #ffffff; */
    text-decoration: none;
    text-align: center;
    transition: filter 200ms linear 0s;
  }
  .linksfornav:nth-of-type(1) {
    padding-top: 24px;
  }
  .linksfornav:nth-last-of-type(1) {
    border-bottom: none;
  }
  .linksfornav:hover {
    color: white;
  }

  @keyframes menuAnim {
    0% {
      height: 0;
    }
    100% {
      height: 312px;
    }
  }
  @keyframes fadeMe {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 0;
    }
  }
  

  .navbarcontainer{
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 0px;
    height: 60px;
    display: flex;
    box-shadow: rgba(18, 18, 32, 0.2) 0px 7px 29px 0px;
    z-index: 100;
  }

  .titlecon{
      width: 100vw;
      height: 60px;
      display: flex;
        align-items: center;
        /* justify-content: center; */
        position: fixed;
        z-index: -1;
        justify-content: flex-end;

    }

  .title_nav{
      color: rgb(0, 0, 0);
      /* font-family: 'Courgette', cursive; */
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
  }

  .fas{

      color: rgb(0, 0, 0);
      margin-right: 20px;
      font-size: 35px;
  }

  .logoimg{
    height: 40px ;
    width: auto;
  }

  .logoandtitle{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
      margin-right: 10px;
      width: 180px;
  }

  a:-webkit-any-link {
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
}


