.footer{
    width: 100vw;
    height: 100px;
    background-color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 10px;
    font-family: 'Outfit', sans-serif;

}